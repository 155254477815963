import React from 'react'
import RamMandirSlider from './Assets/Images/ram-mandir.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const RamMandirModelSlider = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
                <img src={RamMandirSlider} className='w-100' alt='...' style={{height: '400px'}}/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default RamMandirModelSlider