import React from 'react'
import SkatingSlider from './SkatingSlider'
import Skating1 from './Assets/Images/skating/skating-1.png'
import Skating2 from './Assets/Images/skating/skating-2.png'
import Skating3 from './Assets/Images/skating/skating-3.png'
import Skating4 from './Assets/Images/skating/skating-4.png'
import Skating5 from './Assets/Images/skating/skating-5.png'
import Skating6 from './Assets/Images/skating/skating-6.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';

const Skating = () => {
  return (
    <>
      <SkatingSlider/>
      <div className='container-fluid'>
        <div className='row mt-4'>
          <div className='col-12 text-center'>
            <p className='display-6'>
              My Roller Skating Journey
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6 text-center'>
            <MDBCarousel showControls>
              <MDBCarouselItem itemId={1}>
                <img src={Skating1} className='w-100' alt='...'/>
              </MDBCarouselItem>
              <MDBCarouselItem itemId={2}>
                <img src={Skating2} className='w-100' alt='...'/>
              </MDBCarouselItem>
              <MDBCarouselItem itemId={3}>
                <img src={Skating3} className='w-100' alt='...'/>
              </MDBCarouselItem>
              <MDBCarouselItem itemId={4}>
                <img src={Skating4} className='w-100' alt='...'/>
              </MDBCarouselItem>
              <MDBCarouselItem itemId={5}>
                <img src={Skating5} className='w-100' alt='...'/>
              </MDBCarouselItem>
              <MDBCarouselItem itemId={6}>
                <img src={Skating6} className='w-100' alt='...'/>
              </MDBCarouselItem>
            </MDBCarousel>
          </div>
          <div className='col-md-3'></div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 text-center'>
            <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
              Back to My Activities
            </MDBBtn>
          </div>
        </div>
      </div>
    </>
  )
}

export default Skating