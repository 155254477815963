import React from 'react'
import MyTypoHeader from './MyTypoHeader'
import Typo1 from './Assets/Images/typography/typo-1.png'
import Typo2 from './Assets/Images/typography/typo-2.png'
import { MDBBtn } from 'mdb-react-ui-kit';
const MyTypo = () => {
  return (
    <>
        <MyTypoHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center mb-2'>
                    <p className='display-6'>
                        My Topography
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img src={Typo1} alt='typo1' className='img-fluid'/>
                </div>
                <div className='col-md-6 text-center'>
                    <img src={Typo2} alt='typo2' className='img-fluid'/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default MyTypo