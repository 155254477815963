import React from 'react'
import ChandrayanHeader from './Assets/Images/mission-chandrayan.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
const ChandrayaanMissionSlider = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
            <img src={ChandrayanHeader} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default ChandrayaanMissionSlider