import React from 'react'
import DentalHeader from './Assets/Images/dental.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const DentalWellnessSlider = () => {
  return (
    <>
     <MDBCarousel>
      <MDBCarouselItem itemId={1}>
        <img src={DentalHeader} className='w-100' alt='...'/>
      </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default DentalWellnessSlider