import React from 'react'
import SkatingSlider from './SkatingSlider'
import SkatingCelebrations from './Assets/Images/skating-celebration.png'
import SkatingRecord2 from './Assets/Images/skating-record-2.png'
import { MDBBtn } from 'mdb-react-ui-kit';
import NavbarInner from './NavbarInner';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const SkatingCelebration = () => {
  return (
    <>        
        <NavbarInner/>    
        <SkatingSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        World Record Celebration
                    </p>
                </div>
            </div>
            <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <MDBCarousel showControls>
                <MDBCarouselItem itemId={1}>
                    <img src={SkatingCelebrations} className='w-100' alt='...'/>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                    <img src={SkatingRecord2} className='w-100' alt='...'/>
                </MDBCarouselItem>
                </MDBCarousel>
            </div>
            <div className='col-md-3'></div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default SkatingCelebration