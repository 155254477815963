import React from 'react'
import NavbarInner from './NavbarInner'
import RoarUpLogoDesignSlider from './RoarUpLogoDesignSlider'
import Roarup1 from './Assets/Images/roarup-design/roarup-1.png'
import Roarup2 from './Assets/Images/roarup-design/roarup-2.png'
import Roarup3 from './Assets/Images/roarup-design/roarup-3.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';

const RoarUpLogoDesign = () => {
  return (
    <>
        <NavbarInner/>
        <RoarUpLogoDesignSlider/>
        <div className='container-fluid'>
          <div className='row mt-4'>
            <div className='col-12 text-center'>
              <p className='display-6'>
                My RoarUp Bharat Logo Design
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <MDBCarousel showControls>
                <MDBCarouselItem itemId={1}>
                    <img src={Roarup1} className='w-100' alt='...'/>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                    <img src={Roarup2} className='w-100' alt='...'/>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    <img src={Roarup3} className='w-100' alt='...'/>
                </MDBCarouselItem>
                </MDBCarousel>
            </div>
            <div className='col-md-3'></div>
          </div>
          <div className='row mb-2 mt-2'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                        Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default RoarUpLogoDesign