import React from 'react'
import RoarUpBanner from './Assets/Images/roarup-design.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const RoarUpLogoDesignSlider = () => {
  return (
    <>
         <MDBCarousel>
            <MDBCarouselItem itemId={1}>
            <img src={RoarUpBanner} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default RoarUpLogoDesignSlider