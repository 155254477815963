import React from 'react'
import DentalWellnessSlider from './DentalWellnessSlider'
import DentalWellnessImage from './Assets/Images/dental-wellness.png'
import { MDBBtn } from 'mdb-react-ui-kit';

const DentalWellness = () => {
  return (
    <>
        <DentalWellnessSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        My Dental Wellness Routine
                    </p>
                </div>
                <div className='col-12 text-center'>
                    <img src={DentalWellnessImage} alt='dental wellness'/>
                </div>
                <div className='col-12 mt-2 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                        Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default DentalWellness