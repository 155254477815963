import React from 'react'
import BadmintonHeader from './Assets/Images/badminton-day.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const BadmintonSlider = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
                <img src={BadmintonHeader} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default BadmintonSlider