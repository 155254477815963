import React from 'react'
import NavbarInner from './NavbarInner'
import MyActivitiesSlider from './MyActivitiesSlider'
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const MyActivities = () => {
  return (
    <>
        <NavbarInner/>
        <MyActivitiesSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>My Activities So Far...</p>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='missionchandrayan'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/3d-fluency/100/satellite.png" alt="satellite"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Mission Chandrayan
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='skatingnationalflag'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/bubbles/100/man-with-beard-medal.png" alt="man-with-beard-medal"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    2<sup>nd</sup> Skating World Record
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='skatingceleb'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/cotton/100/trophy--v2.png" alt="trophy--v2"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    World Record Award Invite
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='story1'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/color/100/book-and-pencil.png" alt="external-Job-Search-recruitment-nawicon-flat-nawicon"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Story Writing
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='skatingjourney'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/100/external-skating-winter-olympics-flaticons-lineal-color-flat-icons-4.png" alt="external-skating-winter-olympics-flaticons-lineal-color-flat-icons-4"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Skating
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='jacketshopping'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/stickers/100/puffer-jacket.png" alt="puffer-jacket"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    My Jacket Shopping
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='badminton'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/100/external-badminton-summer-olympics-flaticons-lineal-color-flat-icons-2.png" alt="external-badminton-summer-olympics-flaticons-lineal-color-flat-icons-2"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    1st Badminton Day
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='spaceactivity'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/emoji/100/rocket-emji.png" alt="rocket-emji"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    My Space Activity
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='robotics'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/external-flaticons-flat-flat-icons/100/external-robotics-3d-printing-flaticons-flat-flat-icons.png" alt="external-robotics-3d-printing-flaticons-flat-flat-icons"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    1st Robotics Learning
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='ravanpreparation'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/external-icongeek26-outline-colour-icongeek26/100/external-monk-buddhism-icongeek26-outline-colour-icongeek26.png" alt="external-monk-buddhism-icongeek26-outline-colour-icongeek26"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Ravan Model Preparation
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='dental'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/dusk/100/tooth.png" alt="tooth"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Routine Dental Wellness
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='mylogo'>                    
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/color/100/a-cute.png" alt="a-cute"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    My Logo Designs
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='roaruplogo'>                    
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/officel/100/lion-statue.png" alt="lion-statue"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Logo Design for RoarUp
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='rammandirmodel'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/emoji/100/hindu-temple.png" alt="hindu-temple"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Ram Mandir Modelling
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <a href='mytypo'>
                        <Card sx={{ maxWidth: '100%' }}>
                            <CardActionArea>
                                <div className='card-image'>
                                <img width="100" height="100" src="https://img.icons8.com/pastel-glyph/100/generic-text.png" alt="generic-text"/>
                                </div>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Typography
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </a>
                </div>
            </div>
        </div>
    </>
  )
}

export default MyActivities