import React from 'react'
import NavbarInner from './NavbarInner'
import WOTDSlider from './WOTDSlider'

const WordOfTheDay = () => {
  return (
    <>
        <NavbarInner/>
        <WOTDSlider/>
    </>
  )
}

export default WordOfTheDay