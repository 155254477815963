import React from 'react'
import NavbarInner from './NavbarInner'
import SkatingNationalFlagSlider from './SkatingNationalFlagSlider'
import SkatingRecord1 from './Assets/Images/skating-world-record-2/skating-record-2-1.png'
import SkatingRecord2 from './Assets/Images/skating-world-record-2/skating-record-2-2.png'
import SkatingRecord3 from './Assets/Images/skating-world-record-2/skating-record-2-3.png'
import SkatingRecord4 from './Assets/Images/skating-world-record-2/skating-record-2-4.png'
import SkatingRecord5 from './Assets/Images/skating-world-record-2/skating-record-2-5.png'
import SkatingRecord6 from './Assets/Images/skating-world-record-2/skating-record-2-6.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';

const SkatingNationalFlag = () => {
  return (
    <>
        <NavbarInner/>
        <SkatingNationalFlagSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>World Record for Skaters Performing Skating Holding the National Flag</p>
                </div>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={SkatingRecord1} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={SkatingRecord2} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={SkatingRecord3} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={4}>
                            <img src={SkatingRecord4} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={5}>
                            <img src={SkatingRecord5} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={6}>
                            <img src={SkatingRecord6} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default SkatingNationalFlag