import React from 'react'
import NavbarInner from './NavbarInner'
import RavanPreparationHeader from './RavanPreparationHeader'
import RavanModel from './Assets/Images/ravan-preparation.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';

const RavanPreparation = () => {
  return (
    <>
        <NavbarInner/>
        <RavanPreparationHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>My Ravan Model Preparation</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={RavanModel} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default RavanPreparation