import React from 'react'
import RavanPreparationSlider from './Assets/Images/ravan-preparation-header.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const RavanPreparationHeader = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
            <img src={RavanPreparationSlider} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default RavanPreparationHeader