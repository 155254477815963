import React, { useState } from 'react'
import AayanshLogoWeb from './Assets/Images/aayansh-logo-web.png'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBIcon
  } from 'mdb-react-ui-kit';

const NavbarInner = () => {
    const [openNav, setOpenNav] = useState(false);
  return (
    <>
        <MDBNavbar expand='lg' dark bgColor='dark' sticky='top'>
      <MDBContainer fluid>
      <MDBNavbarBrand href='/'>
                    <img src={AayanshLogoWeb} alt='Aayansh Website Logo' className='img-fluid'/>
                </MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenNav(!openNav)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar open={openNav}>
          <MDBNavbarNav>
            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' href='/'>
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink active href="wotd">Word Of The Day</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
            <MDBNavbarLink active href='myactivities'>My Activities</MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
    </>
  )
}

export default NavbarInner