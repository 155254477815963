import React from 'react'
import BadmintonSlider from './BadmintonSlider'
import Badminton1 from './Assets/Images/badminton/badminton-1.png'
import Badminton2 from './Assets/Images/badminton/badminton-2.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';

const Badminton = () => {
  return (
    <>
        <BadmintonSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        My 1st Badminton Day
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={Badminton1} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={Badminton2} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default Badminton