import React from 'react'
import MyLogoHeader from './MyLogoHeader'
import MyLogo1 from './Images/mylogo.png'
import { MDBBtn } from 'mdb-react-ui-kit';

const MyLogo = () => {
  return (
    <>
        <MyLogoHeader/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        My Logo Designs
                    </p>
                </div>
                <div className='col-12 text-center'>
                    <img className='img-fluid' src={MyLogo1} alt='My Logo'/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default MyLogo