import React from 'react'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import ActivitiesSliderImage from '../Components/Assets/Images/activities.png'

const MyActivitiesSlider = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
                <img src={ActivitiesSliderImage} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default MyActivitiesSlider