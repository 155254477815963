import React, { useState } from 'react'
import AayanshLogoWeb from './Assets/Images/aayansh-logo-web.png'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    // MDBNavbarItem,
    MDBNavbarLink,
    MDBIcon,
    MDBCollapse
  } from 'mdb-react-ui-kit';

const Navbar = () => {
    const [openNavSecond, setOpenNavSecond] = useState(false);
  return (
    <>
        <MDBNavbar expand='lg' dark fixed='top' className='shadow-0'>
            <MDBContainer fluid>
                <MDBNavbarBrand href='/'>
                    <img src={AayanshLogoWeb} alt='Aayansh Website Logo' className='img-fluid'/>
                </MDBNavbarBrand>
                <MDBNavbarToggler
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={() => setOpenNavSecond(!openNavSecond)}
                >
                <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>
                <MDBCollapse navbar open={openNavSecond}>
                <MDBNavbarNav>
                    <MDBNavbarLink active aria-current='page' href='/'>
                    Home
                    </MDBNavbarLink>
                    <MDBNavbarLink active href="wotd">Word Of The Day</MDBNavbarLink>
                    <MDBNavbarLink active href='myactivities'>My Activities</MDBNavbarLink>
                </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    </>
  )
}

export default Navbar