import React from 'react'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import SkatingSliderImage from '../Components/Assets/Images/roller-skating-journey.png'

const SkatingSlider = () => {
  return (
    <>
      <MDBCarousel>
        <MDBCarouselItem itemId={1}>
          <img src={SkatingSliderImage} className='w-100' alt='...'/>
        </MDBCarouselItem>
      </MDBCarousel>
    </>
  )
}

export default SkatingSlider