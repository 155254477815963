import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Components/Home'
import Story1 from "./Components/Story1";
import WOTD from "./Components/WordOfTheDay";
import MyActivities from './Components/MyActivities'
import SkatingJourney from './Components/Skating'
import RamMandirModel from "./Components/RamMandirModel";
import Badminton from "./Components/Badminton";
import Robotics from "./Components/Robotics";
import DentalWellness from "./Components/DentalWellness";
import MyLogo from "./Components/Assets/MyLogo";
import MyTypo from "./Components/MyTypo";
import SkatingCelebration from "./Components/SkatingCelebration";
import SpaceActivity from "./Components/SpaceActivity";
import RoarUpLogoDesign from "./Components/RoarUpLogoDesign";
import SkatingNationalFlag from "./Components/SkatingNationalFlag";
import ChandrayanMission from "./Components/ChandrayanMission";
import JacketShopping from "./Components/JacketShopping";
import RavanPreparation from "./Components/RavanPreparation";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element  = {<Home/>}/>
          <Route path="/" element = {<Home/>}/>
          <Route path="story1" element = {<Story1/>}></Route>
          <Route path="wotd" element = {<WOTD/>}></Route>
          <Route path="myactivities" element = {<MyActivities/>}></Route>
          <Route path="skatingjourney" element = {<SkatingJourney/>}></Route>
          <Route path="rammandirmodel" element = {<RamMandirModel/>}></Route>
          <Route path="badminton" element = {<Badminton/>}></Route>
          <Route path="robotics" element = {<Robotics/>}></Route>
          <Route path="dental" element = {<DentalWellness/>}></Route>
          <Route path="mylogo" element = {<MyLogo/>}></Route>
          <Route path="mytypo" element = {<MyTypo/>}></Route>
          <Route path="skatingceleb" element = {<SkatingCelebration/>}></Route>
          <Route path="spaceactivity" element = {<SpaceActivity/>}></Route>
          <Route path="roaruplogo" element = {<RoarUpLogoDesign/>}></Route>
          <Route path="skatingnationalflag" element = {<SkatingNationalFlag/>}></Route>
          <Route path="missionchandrayan" element = {<ChandrayanMission/>}></Route>
          <Route path="jacketshopping" element = {<JacketShopping/>}></Route>
          <Route path="ravanpreparation" element = {<RavanPreparation/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
