import React from 'react'
import RamMandirModelSlider from './RamMandirModelSlider'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import RamMandir1 from './Assets/Images/ram-mandir/ram-1.png'
import RamMandir2 from './Assets/Images/ram-mandir/ram-2.png'
import RamMandir3 from './Assets/Images/ram-mandir/ram-3.png'
import RamMandir4 from './Assets/Images/ram-mandir/ram-4.png'
import RamMandir5 from './Assets/Images/ram-mandir/ram-5.png'
import RamMandir6 from './Assets/Images/ram-mandir/ram-6.png'
import RamMandir7 from './Assets/Images/ram-mandir/ram-7.png'
import { MDBBtn } from 'mdb-react-ui-kit';

const RamMandirModel = () => {
  return (
    <>
        <RamMandirModelSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        Ram Mandir Remodelling
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={RamMandir1} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={RamMandir2} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={RamMandir3} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={4}>
                            <img src={RamMandir4} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={5}>
                            <img src={RamMandir5} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={6}>
                            <img src={RamMandir6} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={7}>
                            <img src={RamMandir7} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
                <div className='col-md-3'></div>
            </div>
            <div className='row mb-2 mt-2'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                        Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default RamMandirModel