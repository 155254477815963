import React from 'react'
import MyTypos from './Assets/Images/typography.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const MyTypoHeader = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
            <img src={MyTypos} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default MyTypoHeader