import React from 'react'
import NavbarInner from './NavbarInner'
import ChandrayaanMissionSlider from './ChandrayaanMissionSlider'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';
import Chandrayan1 from './Assets/Images/chandrayan/chandrayaan-1.png'
import Chandrayan2 from './Assets/Images/chandrayan/chandrayaan-2.png'
import Chandrayan3 from './Assets/Images/chandrayan/chandrayaan-3.png'

const ChandrayanMission = () => {
  return (
    <>
        <NavbarInner/>
        <ChandrayaanMissionSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        My Chandrayan Mission Activity
                    </p>
                </div>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={Chandrayan1} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={Chandrayan2} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={Chandrayan3} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default ChandrayanMission