import React from 'react'
import SpaceSlider from './SpaceSlider'
// import {
//     MDBCard,
//     MDBCardBody,
//     MDBCardTitle,
//     MDBCardText,
//     MDBBadge,
//     MDBCardImage,
//   MDBBtn
//     } from 'mdb-react-ui-kit';
//     import Card from '@mui/material/Card';
//     // import CardActions from '@mui/material/CardActions';
//     import CardContent from '@mui/material/CardContent';
//     // import CardMedia from '@mui/material/CardMedia';
//     // import Button from '@mui/material/Button';
//     import Typography from '@mui/material/Typography';
//     import { CardActionArea } from '@mui/material';
import Navbar from './Navbar';
  

const Home = () => {
  return (
    <>
       <Navbar/> 
       <SpaceSlider/>
        {/* <div className='container-fluid wotd'>
            <div className='row'>
                <div className='col-12 text-center my-2'>
                    <p className='display-4'> Word Of The Day</p>
                    <MDBCard className='shadow'>
                        <MDBCardBody>
                            <MDBCardTitle>Accident: </MDBCardTitle>
                            <MDBCardText>
                            <MDBBadge pill color='success' light>
                                Noun
                            </MDBBadge> an unfortunate mishap; especially one causing damage or injury
                            </MDBCardText>
                            <MDBCardText>Example: Winning the lottery was a happy accident.</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </div>
        </div> */}
        {/* <div className='container-fluid my-activities' >
            <div className='row'>
                <div className='col-12 text-center my-2'>
                    <p className='display-4'>My Activities</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/color/100/book-and-pencil.png" alt="external-Job-Search-recruitment-nawicon-flat-nawicon"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Story Writing
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/100/external-skating-winter-olympics-flaticons-lineal-color-flat-icons-4.png" alt="external-skating-winter-olympics-flaticons-lineal-color-flat-icons-4"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Skating
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/100/external-badminton-summer-olympics-flaticons-lineal-color-flat-icons-2.png" alt="external-badminton-summer-olympics-flaticons-lineal-color-flat-icons-2"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                1st Badminton Day
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/external-flaticons-flat-flat-icons/100/external-robotics-3d-printing-flaticons-flat-flat-icons.png" alt="external-robotics-3d-printing-flaticons-flat-flat-icons"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                1st Robotics Learning
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/dusk/100/tooth.png" alt="tooth"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Routine Dental Wellness
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/color/100/a-cute.png" alt="a-cute"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                My Logo Designs
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/emoji/100/hindu-temple.png" alt="hindu-temple"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Ram Mandir Modelling
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div className='col-md-3 text-center mt-2'>
                    <Card sx={{ maxWidth: '100%' }}>
                        <CardActionArea>
                            <div className='card-image'>
                            <img width="100" height="100" src="https://img.icons8.com/pastel-glyph/100/generic-text.png" alt="generic-text"/>
                            </div>
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Typography
                            </Typography>
                            
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </div>
        </div> */}
    </>
  )
}

export default Home