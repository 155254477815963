import React from 'react'
import RoboticsSlider from './RoboticsSlider'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';
import Robotics1 from './Assets/Images/robotics/robotics-1.png'
import Robotics2 from './Assets/Images/robotics/robotics-2.png'
import Robotics3 from './Assets/Images/robotics/robotics-3.png'
import Robotics4 from './Assets/Images/robotics/robotics-4.png'

const Robotics = () => {
  return (
    <>
        <RoboticsSlider/>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        My 1st Robotics Learning Experience
                    </p>
                </div>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <MDBCarousel showControls>
                        <MDBCarouselItem itemId={1}>
                            <img src={Robotics1} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={Robotics2} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={Robotics3} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={4}>
                            <img src={Robotics4} className='w-100' alt='...'/>
                        </MDBCarouselItem>
                    </MDBCarousel>
                </div>
                <div className='col-md-3'></div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default Robotics