import React from 'react'
import LogoHeader from './Images/logo-banner.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const MyLogoHeader = () => {
  return (
    <>
         <MDBCarousel>
      <MDBCarouselItem itemId={1}>
        <img src={LogoHeader} className='w-100' alt='...'/>
      </MDBCarouselItem>
    </MDBCarousel>
    </>
  )
}

export default MyLogoHeader