import React from 'react'
import RoboticsHeader from './Assets/Images/robotics-header.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const RoboticsSlider = () => {
  return (
    <>
        <MDBCarousel>
      <MDBCarouselItem itemId={1}>
        <img src={RoboticsHeader} className='w-100' alt='...'/>
      </MDBCarouselItem>
    </MDBCarousel>
    </>
  )
}

export default RoboticsSlider