import React,{useState} from 'react'
import RabbitTV from '../Components/Assets/Images/rabbit-watching-tv.png'
import StorySlider from './StorySlider'
import Story1Audio from '../Components/Assets/audio/story-1.opus'
import NavbarInner from './NavbarInner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { MDBBtn } from 'mdb-react-ui-kit';

const Story1 = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = React.createRef();
  
    const togglePlay = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };
  return (
    <>
      <NavbarInner/>
        <div className='container-fluid my-2'>
            <div className='row'>
                <div className='col-md-6'>
                    <p align='justify'>There was a small Rabbit named Roma. He was always watching T.V, Mobile and computer nine hours everyday. One day his eyesite was weak he was not able to se anything. he went to doctor mr Rohan he gave specks to Roma. but his eyesite was never become good because he never avoid watching TV and Mobile but watching watchig and watching TV and mobile. one day his mother is very angry. She asked avoid watching tv and mobile but he sed no the mother was vey angry she take mobile from his hands and snached the Roma's mobile. Roma has on the T.V and and start watching. but Roma's mother has off the T.V and snached T.V's remote. Roma was crying he has nothing to watch T.V and mobile but Roma's father was a mecanic he repaired T.V's remote and mobile and asked something to Roma. Watch T.V one hour and mobile fiteen minutes and exersize with your eyes up down, left right in morning it is healthy for your eyes.</p>
                    <p>
                        <StorySlider/>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={RabbitTV} alt='rabbit watching tv'/>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert variant="outlined" severity="info">
                        <p>Listen to my story here :
                      <audio ref={audioRef} src={Story1Audio} />
                      <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button>
                      </p>
                      </Alert>
                    </Stack>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <MDBBtn className='me-1  mb-2' color='success' href='myactivities'>
                    Back to My Activities
                    </MDBBtn>
                </div>
            </div>
        </div>
    </>
  )
}

export default Story1