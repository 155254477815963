import React from 'react'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import Alphabets from '../Components/Assets/Images/alphabets.png'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const WOTDSlider = () => {
  return (
    <>
        <MDBCarousel>
            <MDBCarouselItem itemId={1}>
                <img src={Alphabets} className='w-100' alt='...'/>
            </MDBCarouselItem>
        </MDBCarousel>
        <div className='container-fluid'>
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <p className='display-6'>Word Of The Day</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-center'>
                    <p className='dancing-script display-4' style={{color: 'purple'}}>damp</p>
                    <p className='display-6'>
                        <Stack spacing={1} alignItems="center">
                            <Stack direction="row" spacing={1}>
                                <Chip label="adjective" color="success" variant="outlined" />
                            </Stack>
                        </Stack>
                    </p>
                </div>
                <div className='col-md-6 text-center'>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert variant="outlined" severity="success">
                        slightly wet<br/><br/>
                            <p align="left"><strong>Example:</strong> The clothes were still damp after being left out in the rain last night.</p>
                        </Alert>
                    </Stack>
                </div>
            </div>
        </div>
    </>
  )
}

export default WOTDSlider