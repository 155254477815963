import React from 'react'
import Space1 from './Assets/Videos/space-1.mp4'
import { MDBBtn } from 'mdb-react-ui-kit';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AayanshLogo from './Assets/Images/aayansh-logo.png'

const SpaceSlider = () => {
    const hobbies = ["Astronaut", "Engineer", "Musician", "Sportsman", "Scientist", "Artist"];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    arrows: false,
  };
  return (
    <div>
        <div className='main-slider'>
            <div className='overlay'></div>
            <video src={Space1} muted autoPlay loop/>            
            <div className='hero-content'>
            <img src={AayanshLogo} alt='logo' className='img-fluid' />
                <h1 className='display-3 text-center text-white'>Aayansh Kumar</h1>
                <p className='display-5 text-white'>Let's Innovate & Celebrate</p>
                <Slider {...settings} style={{ color:'white'}} >
            {hobbies.map((text, index) => (
              <div key={index} className="item" style={{color:'white !important'}}>
                {text}
              </div>
            ))}
          </Slider>
                <div className="d-grid gap-2 col-4 mx-auto">
                <MDBBtn color='info' className='my-2' href={'wotd'}>Let's Go</MDBBtn>
                </div>
        </div>
      </div>
    </div>
  )
}

export default SpaceSlider