import React from 'react'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import Story11 from '../Components/Assets/Images/story1-1.png'
import Story12 from '../Components/Assets/Images/story1-2.png'

const StorySlider = () => {
  return (
    <>
    <MDBCarousel showControls>
      <MDBCarouselItem itemId={1}>
        <img src={Story11} className='w-75' alt='...' style={{height: '400px'}}/>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2}>
        <img src={Story12} className='w-75' alt='...' style={{height: '400px'}}/>
      </MDBCarouselItem>
    </MDBCarousel>
    </>
  )
}

export default StorySlider